<template>
  <div id="form-crud-user">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="card border-0 shadow p-3">
      <form @submit.prevent="initSubmit">

        <h4 class="">{{ $t('common.globalInfo') }}</h4>
        <hr />

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="username">{{ $t('common.username') }} *</label>
            <input class="form-control" id="username" v-model="user.username" type="email">
            <div v-if="errorsForm.username">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.username.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="firstname">{{ $t('common.firstname') }} *</label>
            <input class="form-control" id="firstname" v-model="user.firstname" type="text">
            <div v-if="errorsForm.firstname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.firstname.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="lastname">{{ $t('common.lastname') }} *</label>
            <input class="form-control" id="lastname" v-model="user.lastname" type="text">
            <div v-if="errorsForm.lastname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.lastname.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="password">{{ $t('common.password') }}</label>
            <input class="form-control" id="password" v-model="user.password" type="password">
            <div v-if="errorsForm.password">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.password.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group" v-if="$store.getters.userIsAdmin">
            <label>Role(s) *</label>
            <div class="ml-5" v-for="(role, index) in UserModule.roles" :key="index">
              <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'roles-' + index"
                  :checked="(user.roles) ? user.roles.includes(role.tag) : false"
                  :value="role.tag"
                  @change="checkboxRoles($event, role.tag)"
              />

              <label :for="'roles-' + index" class="ml-2">{{ role.name }}</label>
            </div>
            <div v-if="errorsForm.roles">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.roles.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="company">{{ $t('common.company') }} *</label>
            <input class="form-control" id="company" v-model="user.company" type="text">
            <div v-if="errorsForm.company">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.company.errors" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>

        <h4 v-if="$store.getters.getCurrentUser.id !== user.id && $store.getters.userIsAdmin" class="mt-3 mb-3">
          Config
        </h4>
        <hr v-if="$store.getters.getCurrentUser.id !== user.id && $store.getters.userIsAdmin"/>

        <div v-if="$store.getters.getCurrentUser.id !== user.id && $store.getters.userIsAdmin" class="col-12 col-md-6">
          <div class="col-6">
            <div class="form-group">
              <label>{{ $t('pages.user.activateUser') }}</label>

              <div class="form-check">
                <input class="form-check-input" type="radio" id="is-active-yes" v-model="user.isActive" :checked="user.isActive === true"  v-bind:value="true">
                <label class="form-check-label" for="is-active-yes">
                  {{ $t('common.yes') }}
                </label>
              </div>
              <div class="form-check" >
                <input class="form-check-input" type="radio" id="is-active-no" v-model="user.isActive" :checked="user.isActive === false" v-bind:value="false">
                <label class="form-check-label" for="is-active-no">
                  {{ $t('common.no') }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <input type="submit" class="btn mt-4 ml-3 mb-2 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    user: Object,
    id: Number,
    actionForm: String
  },
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      errorsForm: [],
    }
  },
  created: function() {
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- CHECKBOX ------------------*/
    checkboxRoles(event, role) {
      if(event.target.checked) {
        this.user.roles.push(role)
      } else {
        let indexRole = this.user.roles.findIndex(array => array === role)
        if(indexRole > -1) {
          this.user.roles.splice(indexRole, 1)
        }
      }
    },
    /*----------------- SUBMIT ------------------*/
    initSubmit()
    {
      // If user (create or update)
      if(this.actionForm === 'edit')
      {
        this.editUser()
      }
      else {
        this.createUser()
      }
    },
    /**
     * Create user (submit form)
     */
    createUser() {

      this.showLoader()

      this.$store.dispatch('createUser', this.user)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update user (submit form)
     */
    editUser() {
      this.showLoader()

      this.$store.dispatch('updateUser', { id: this.user.id, user: this.user })
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to user-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      this.$router.push({ name: 'user-index' })

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit')
      {
        value = this.$t('pages.user.editUser')
      }
      else {
        value = this.$t('pages.user.createUser')
      }

      return value
    },
    ...mapState(['UserModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>

<style scoped>

</style>
